import PoppinsBold from "../assets/fonts/Poppins/Poppins-Bold.ttf";
import PoppinsLight from "../assets/fonts/Poppins/Poppins-Light.ttf";
import PoppinsRegular from "../assets/fonts/Poppins/Poppins-Regular.ttf";
import PoppinsMedium from "../assets/fonts/Poppins/Poppins-Medium.ttf";
import PoppinsSemiBold from "../assets/fonts/Poppins/Poppins-SemiBold.ttf";

import type { ThemeOptions } from "@mui/material/styles";


export const colors = {
  black: "#162133",
  lightBlack: "#151521",
  lighterBlack: "#1b1b2c",
  lightestBlack: "#27273F",
  borderColor: "rgba(0, 0, 0, 0.1)",
  lighterBlackBorder: "#3e3946",
  lightBlackBorder: "rgba(64, 57, 70, 0.37)",
  blackTooltip: "#1b1b25",
  darkGrey: "#808080",
  blue: "#017aff",
  green: "#08a441",
  red: "#b52a00",
  yellow: "#cca561",
  offWhite: "#eff4f8c7",
  purple: "#770fc8",
  purpleLight: "#a946f6",
  white: "#ffffff",
  lightWhite: "rgba(255, 255, 255, 0.94)",
};

const theme = (): ThemeOptions => {
  return {
    palette: {
      background: {
        default: colors.offWhite,
        paper: colors.white,
      },
      // grid: {
      //   main: colors.white,
      //   content: colors.offWhite,
      // },
      primary: {
        main: colors.yellow,
        contrastText: 'white',
      },
      secondary: {
        main: colors.yellow,
      },
      text: {
        primary: colors.black,
      },
    },
    typography: {
      fontFamily: "'PlexSans'",
      h1: {
        fontSize: "32px",
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: 1.21,
        letterSpacing: "1.25px",
        "@media (max-width:600px)": {
          fontSize: "21px",
        },
      },
      h2: {
        fontSize: "28px",
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: 1.21,
        letterSpacing: "1.25px",
        "@media (max-width:600px)": {
          fontSize: "18px",
        },
      },
      h3: {
        fontSize: "24px",
        fontWeight: 700,
        textTransform: "capitalize",
        lineHeight: 1.24,
        letterSpacing: "0.8px",
      },
      h4: {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: 1.31,
        letterSpacing: "0.61px",
      },
      h5: {
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "capitalize",
        lineHeight: 1.5,
        letterSpacing: "0.53px",
      },
      subtitle1: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: 1.45,
        letterSpacing: ".42px",
        textTransform: "uppercase",
        opacity: "0.6",
      },
      subtitle2: {
        fontSize: "12px",
        fontWeight: 500,
        textTransform: "capitalize",
        lineHeight: 1.08,
        letterSpacing: "0.46px",
      },
      h6: {
        fontSize: "14px",
        fontWeight: 600,
        textTransform: "capitalize",
        lineHeight: 1.43,
        letterSpacing: "0.53px",
      },
      caption: {
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "capitalize",
        lineHeight: 1.43,
        letterSpacing: "0.53px",
      },
      body1: {
        fontSize: "16px",
        fontWeight: 500,
        letterSpacing: "0.61px",
        lineHeight: 1.31,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        
          @font-face {
            font-family: 'PlexMono';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('PlexMono-Medium'),
              url(${PoppinsBold}) format('woff2');
          }        

          @font-face {
            font-family: 'PlexMono';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('PlexMono-Medium'),
              url(${PoppinsMedium}) format('woff2');
          }  

          @font-face {
            font-family: 'PlexSans';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: local('PlexSans-SemiBold'),
              url(${PoppinsSemiBold}) format('woff2');
          }  
          
          @font-face {
            font-family: 'PlexSans';
            fontWeight: 500;
            fontStyle: normal;
            fontDisplay: swap;
            src: local('PlexSans-Medium'),
            url(${PoppinsMedium}) format('woff2');
          }

          @font-face {
            font-family: 'PlexSans';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('PlexSans-Regular'),
              url(${PoppinsRegular}) format('woff2');
          }

          @font-face {
            font-family: 'PlexSans';
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: local('PlexSans-Light'),
              url(${PoppinsLight}) format('woff2');
          }    
        
        `
      },
      MuiTypography: {
        defaultProps: {
          gutterBottom: true
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "14px",
            color: colors.white,
            backgroundColor: colors.blackTooltip,
          },
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false,
        },
        styleOverrides: {
          root: {
            borderRadius: '25px !important'
          }
        }
      },

    },
  };
};

export default theme;
