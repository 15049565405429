import initialState from "../initialState";
import { REMOVE_USER, SET_USER } from "../actions/user";
import { createReducer } from "@reduxjs/toolkit";

const user = createReducer(initialState.user, {
  [REMOVE_USER]: () => {
    return initialState.user;
  },

  [SET_USER]: (state, action) => {
    return (state = action.payload);
  },
});

export default user;
