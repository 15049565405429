import { combineReducers } from "redux";
import session from "./session";
import user from "./user";
import alerts from "./alerts";
import settings from "./settings";

const rootReducer = combineReducers({
  session,
  user,
  alerts,
  settings,
});

export default rootReducer;
