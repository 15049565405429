import { IResponse } from 'interfaces/general/response.interface';
import { IProduct } from 'interfaces/products.interface';

import { BaseApi, Payload } from './base';


export default new class Products extends BaseApi {

    async createProducts(payload: Payload) {
        const endpointPath = "/products";
        return await this.doRequest(endpointPath, payload, "POST");
    }

    async getProducts(payload: Payload<{ fetch?: boolean }>, abortController = new AbortController()) {
        const endpointPath = `/products` + (payload?.fetch ? '/fetch' : '');
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async getProductsBulk(payload: Payload, abortController = new AbortController()): Promise<IResponse<IProduct[]>> {
        const endpointPath = `/products` + (payload?.fetch ? '/fetch' : '');
        return await this.doRequest(endpointPath, payload, "POST", { signal: abortController.signal });
    }

    async searchPrices(payload: Payload) {
        const endpointPath = `/products`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getProduct(payload: Payload) {
        const endpointPath = `/products/${payload.productId}`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getProductsByStockCode(payload: Payload) {
        const endpointPath = `/products/stockCode/${payload.stockCode}`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getProductsVatNumbers(payload: Payload) {
        const endpointPath = `/products/vat-numbers`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async deleteProducts(payload: Payload) {
        const endpointPath = `/products`;
        return await this.doRequest(endpointPath, payload, "DELETE");
    }

    async deleteProduct(payload: Payload) {
        const endpointPath = `/products/${payload.productId}`;
        return await this.doRequest(endpointPath, payload, "DELETE");
    }

    async updateProduct(payload: Payload, formData: FormData) {
        const endpointPath = `/products/${payload.productId}`;
        return await this.doRequest(endpointPath, formData, "PUT");
    }

    async validateProducts(payload: Payload) {
        return await this.doRequest(`/products/validate`, payload, "POST");
    }

    async getRecipesDependentOnProduct (payload: Payload) {
        return await this.doRequest(`/recipes/by-product/${payload.stockCode}`, payload, "GET");
    }

    async uploadSpecFile(payload: Payload) {
        return await this.doRequest(`/products/upload-single`, payload, "POST");
    }

    async importSupplierPricesInMaterials(payload: Payload) {
        return await this.doRequest(`/products/excel/supplier-info`, payload, "POST");
    }

    async importFinshedGoodsInMaterials(payload: Payload) {
        return await this.doRequest(`/products/excel/finished-goods`, payload, "POST");
    }
}