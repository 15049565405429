import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import useStoreAlertsSelector from "../../../hooks/useStoreAlertsSelector";
import { hideInfoAlert } from "../../../store/actions/alerts";
import "./InfoAlert.scss";

const InfoAlert = () => {
	const storeAlerts = useStoreAlertsSelector();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(hideInfoAlert());
	};

	const getMessage = () => {
		return typeof storeAlerts.info?.message === "string"
		? storeAlerts.info?.message
		: storeAlerts.info?.message?.code
		? storeAlerts.info?.message?.code
		: storeAlerts.info?.message?._message
		? storeAlerts.info?.message?._message
		: "Something happened!"
	}

	return (
		<Snackbar
			TransitionComponent={Slide}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			autoHideDuration={5000}
			className="infoAlert"
			onClose={handleClose}
			open={storeAlerts.info.open}
		>
			<Alert onClose={handleClose} severity="info">
				<AlertTitle>Info!</AlertTitle>
				{getMessage()}
			</Alert>
		</Snackbar>
	);
};

export default InfoAlert;
