
import { BaseApi, Payload } from './base';


export default new class Contacts extends BaseApi {

    async getAllContacts(payload: Payload) {
        return await this.doRequest(`/contacts`, payload, "GET");
    }

    async editContact(id?: string, payload?: Payload) {
        return await this.doRequest(`/contacts/${id}`, payload, "PUT");
    }

    async createContact(payload: Payload) {
        return await this.doRequest(`/contacts`, payload, "POST");
    }

    async getContact(payload: Payload) {
        return await this.doRequest(`/contacts/${payload.id}`, payload, "GET");
    }

    async searchContacts(payload: Payload) {
        return await this.doRequest(`/contacts`, payload, "GET");
    }

    async deleteContacts(payload: Payload) {
        return await this.doRequest(`/contacts`, payload, "DELETE");
    }

    // suppliers
    async getAllSuppliers(payload: Payload, abortController = new AbortController()) {
        return await this.doRequest('/suppliers', payload, "GET", { signal: abortController.signal });
    }

    async getSuppliersForPurchaseOrders(payload: Payload) {
        return await this.doRequest(`/suppliers/orders`, payload, "GET");
    }

    async getSupplier(payload: Payload) {
        return await this.doRequest(`/suppliers/${payload.id}`, payload, "GET");
    }

    async editSupplier(id?: string, payload?: Payload) {
        return await this.doRequest(`/suppliers/${id}`, payload, "PUT");
    }

    async searchSuppliers(payload: Payload) {
        return await this.doRequest(`/suppliers`, payload, "GET");
    }

    async creatSupplier(payload: Payload) {
        return await this.doRequest(`/suppliers`, payload, "POST");
    }

    async deleteSuppliers(payload: Payload) {
        return await this.doRequest(`/suppliers`, payload, "DELETE");
    }

    async deleteSupplier(payload: Payload) {
        return await this.doRequest(`/suppliers/${payload.id}`, payload, "DELETE");
    }

    async createWeeklyOrder(payload: Payload) {
        return await this.doRequest(`/weekly-orders`, payload, "POST")
    }

    async checkDuplicateSupplier(payload: Payload) {
        return await this.doRequest(`/suppliers/check-uniqueness/${payload.code}`, payload, "GET");
    }

}