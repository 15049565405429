import auditTrails from "./auditTrails";
import auth from "./auth";
import { BaseApi, baseUrl } from "./base";
import contacts from "./contacts";
import departments from "./departments";
import productionPlans from "./productionPlans";
import products from "./products";
import recipes from "./recipes";
import users from "./users";
import weeklyStatistics from "./weeklyStatistics";


export { baseUrl };


const api = (new BaseApi())
    .extend(auditTrails)
    .extend(auth)
    .extend(contacts)
    .extend(departments)
    .extend(productionPlans)
    .extend(products)
    .extend(recipes)
    .extend(users)
    .extend(weeklyStatistics)


export default api
