import { IResponse, IPaginatedResponse } from 'interfaces/general/response.interface';
import { BaseApi, Payload } from './base';
import { IRecipe } from 'pages/Recipes/interfaces/recipe.interface';


export default new class Recipes extends BaseApi {
    async searchRecipes(payload: Payload) {
        const endpointPath = `/recipes`;
        return await this.doRequest(endpointPath, payload, "GET");
    }


    async createRecipe(payload: Payload) {
        const endpointPath = "/recipes";
        return await this.doRequest(endpointPath, payload, "POST");
    }

    async createManyRecipes(payload: Payload) {
        const endpointPath = "/recipes/many";
        return await this.doRequest(endpointPath, payload, "POST");
    }

    async validateRecipes(payload: Payload) {
        const endpointPath = `/recipes/validate`;
        return await this.doRequest(endpointPath, payload, "POST");
    }

    async getRecipes(payload: Payload, ab = new AbortController()): Promise<IPaginatedResponse<IRecipe>> {
        const endpointPath = `/recipes`;
        return await this.doRequest(endpointPath, payload, "GET", { signal: ab.signal });
    }

    async getRecipesBulk(payload: Payload, abortController = new AbortController()): Promise<IResponse<IRecipe[]>> {
        const endpointPath = `/recipes` + (payload?.fetch ? '/fetch' : '');
        return await this.doRequest(endpointPath, payload, "POST", { signal: abortController.signal });
    }

    async getRecipeByRecipeNumber(payload: Payload) {
        const endpointPath = `/recipes/${payload.recipeNumber}`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getRecipeVersions(payload: Payload) {
        const endpointPath = `/recipes/${payload.recipeNumber}/versions`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getRecipeById(payload: Payload) {
        const endpointPath = `/recipes/id/${payload.recipeId}`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getRecipeIngredients(payload: Payload, abortController = new AbortController()) {
        const endpointPath = `/recipes/id/${payload.recipeId}/ingredients`;
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async deleteRecipes(payload: Payload) {
        const endpointPath = `/recipes`;
        return await this.doRequest(endpointPath, payload, "DELETE");
    }

    async deleteRecipe(payload: Payload) {
        const endpointPath = `/recipes/${payload.recipeId}`;
        return await this.doRequest(endpointPath, payload, "DELETE");
    }

    async updateRecipe(payload: Payload) {
        const endpointPath = `/recipes/${payload.recipeId}`;
        return await this.doRequest(endpointPath, payload, "PUT");
    }

    async updateRecipeVersioned(payload: Payload) {
        const endpointPath = `/recipes/${payload.recipeNumber}`;
        return await this.doRequest(endpointPath, payload, "PUT");
    }

    async calculateRecipeQuantityPerUnit(payload: Payload) {
        const endpointPath = `/recipes/calculate/perunit`;
        return await this.doRequest(endpointPath, payload, "POST");
    }

    async calculateRecipeBasicQuantity(payload: Payload) {
        const endpointPath = `/recipes/calculate`;
        return await this.doRequest(endpointPath, payload, "POST");
    }
}