import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

export const sidepanelWidth = 295;

export const StyledDashboard = styled(Box)(({ theme }) => ({
	display: "flex",
	flexWrap: "wrap",
	"& > main": {
		flex: 1,
		height: "100vh",
		overflow: "auto",
	},
	"& .MuiDrawer-root": {
		height: "100vh",
		display: "flex",
		maxWidth: `70px`,
		"& > .MuiPaper-root": {
			overflow: "auto",
			width: `${sidepanelWidth}px`,
			maxWidth: `${sidepanelWidth}px`,
			transition: theme.transitions.create("max-width"),
			background: "#162133",
			color: "white",
			position: "relative",
			"& .MuiListItem-root": {
				padding: "16px",
				color: "inherit",
				position: "relative",
				"&::before": {
					content: "''",
					position: "absolute",
					left: 0,
					top: 0,
					height: "100%",
					width: "5px",
					background: "#CCA561",
					transform: "scaleX(0)",
					transition: theme.transitions.create(["transform"]),
				},
				"&.active": {
					backgroundColor: `${theme.palette.primary.main}14`,
					color: "#CCA561",
					"&::before": {
						transform: "scaleX(1)",
					},
				},
				"& .MuiListItemIcon-root": {
					justifyContent: "center",
					transition: theme.transitions.create([
						"min-width",
						"font-size",
						"margin",
					]),
					color: "inherit",
				},
				"& .MuiListItemText-root": {
					// color: theme.pal
					transition: theme.transitions.create(["flex"]),
					overflow: "hidden",
					whiteSpace: "nowrap",
					color: "inherit",
				},
				"& .MuiListItemText-secondary": {
					color: "inherit",
					opacity: 0.5,
				},
			},
			"& .collapse-button": {
				"& .MuiBox-root": {
					transition: theme.transitions.create(["flex"]),
				},
				"& svg": {
					transition: theme.transitions.create(["transform"]),
				},
			},
		},
	},

	"&.collapsed": {
		"& .MuiDrawer-root": {
			"& .MuiPaper-root": {
				maxWidth: "70px",
			},
			"& .MuiListItemIcon-root": {
				margin: "auto",
				minWidth: "24px",
			},
			"& .MuiListItemText-root": {
				flex: 0,
			},
			"& .collapse-button": {
				"& .MuiBox-root": {
					flex: 0,
				},
				"& svg": {
					transformOrigin: "center center",
					transform: "rotate(180deg)",
				},
			},
		},
	},
	[theme.breakpoints.up("md")]: {
		"& .MuiDrawer-root": {
			display: "flex",
			maxWidth: `${sidepanelWidth}px`,
		},
	},
	"& .MuiDivider-root": {
		backgroundColor: "rgba(255, 255,255, 0.12)",
	},
}));

export const StyledSwipableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiPaper-root": {
		width: `${sidepanelWidth}px`,
		background: "#162133",
		color: "white",
	},
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
	"& .MuiListItem-root": {
		padding: "16px",
		color: "inherit",
		position: "relative",
		"&::before": {
			content: "''",
			position: "absolute",
			left: 0,
			top: 0,
			height: "100%",
			width: "5px",
			background: "#CCA561",
			transform: "scaleX(0)",
			transition: theme.transitions.create(["transform"]),
		},
		"&.active": {
			backgroundColor: `${theme.palette.primary.main}14`,
			color: "#CCA561",
			"&::before": {
				transform: "scaleX(1)",
			},
		},
		"& .MuiListItemIcon-root, & .MuiListItemText-root": {
			color: "inherit",
		},
	},
	"& .MuiDivider-root": {
		backgroundColor: "rgba(255, 255,255, 0.12)",
	},
}));
