/**
 * This provider is simply a way to handle permission, by first defining the exported `permissions` in the `consts.ts` file.
 * Then use the PermissionsProvider provider and pass the value of `{userPermissions: []}` which contains the active user's permissions
 * Then to check if a certain rule or permissions is applied to the user, 
 *      you can use the component `WithPermissions` which will render the children _only_ if the conditions have been met,
 *      or you can use the hook `useWithPermissions` which will return an object that contains a boolean property `permitted` which will be true if the conditions have been met,
 *          also a property `permissions` which is a map of the permissions that have been met
 * 
 * Understanding `only`, `onlyAll`, `except` and `exceptAll`,
 *  these are arrays of permission which are the arguments/props passed to the "with permission" util to define the rules
 *  - `only` means if the active user has at least one of the passed permissions, then permitted
 *  - `except` means if the active user has at least one of the passed permissions, then _not_ permitted
 *  - `onlyAll` means if the active user has _all_ of the passed permissions, then permitted
 *  - `exceptAll` means if the active user has _all_ of the passed permissions, then _not_ permitted
 *  the order of these that are checked when passed are only, onlyAll, except and exceptAll, and the first that is _not_ met, will cancel the rest of the checking 
 */



import React, { createContext, useContext, useMemo, useState } from "react";
import { WithPermissionsProps, PermissionsMap } from "./interfaces";


const context = createContext({
    userPermissions: [] as string[],
})


export const PermissionsProvider = context.Provider;



export const useWithPermissions =
    ({
        only,
        onlyAll,
        except,
        exceptAll,
    }: Omit<WithPermissionsProps<any>, 'componentProps' | 'component' | 'children'>) => {
        const { userPermissions: permissions } = useContext(context)

        const permissionsMap: PermissionsMap = useMemo(() => {
            const map: PermissionsMap = {} as any;
            const onlyIntersect = intersect(onlyAll || only || [], permissions);
            const exceptIntersect = intersect(exceptAll || except || [], permissions);

            for (const perm of only || onlyAll || []) {
                map[perm.replace(/\s|-/g, '_') as keyof PermissionsMap] = onlyIntersect.includes(perm);
            }

            for (const perm of except || exceptAll || []) {
                map[perm.replace(/\s|-/g, '_') as keyof PermissionsMap] = exceptIntersect.includes(perm);
            }

            return map;
        }, [except, exceptAll, only, onlyAll, permissions])
        const permitted = useMemo(() => {

            if (permissions?.includes("root")) return true;

            if (!permissions?.length) return true

            if (Array.isArray(only) && !(intersect(only, permissions).length)) {
                return false;
            }

            if (Array.isArray(onlyAll) && !(intersect(onlyAll, permissions).length === onlyAll.length)) {
                return false;
            }

            if (Array.isArray(except) && (intersect(except, permissions).length)) {
                return false;
            }

            return !(Array.isArray(exceptAll) && (intersect(exceptAll, permissions).length === exceptAll.length));

        }, [except, exceptAll, only, onlyAll, permissions])


        return {
            permitted,
            permissions: permissionsMap,
        }
    }

export const WithPermissions =
    <C extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any> | React.FC>
        ({
            children,
            component: Component,
            componentProps = {} as any,
            only,
            onlyAll,
            except,
            exceptAll,
        }: WithPermissionsProps<C>) => {
        const [id] = useState(() => window.btoa((Math.random() * performance.now()).toString()))
        const { permissions, permitted } = useWithPermissions({ only, onlyAll, except, exceptAll });

        if (typeof Component === 'function') {
            return <Component key={id} {...componentProps} permitted={permitted} permissions={permissions} />
        }

        return <React.Fragment key={id}>{permitted ? children : <></>}</React.Fragment>
    }


const intersect = <T extends string | number>(...arrays: T[][]) => {
    let finalArray = arrays.shift() || [];
    for (const array of arrays) {
        finalArray = finalArray.filter(i => array.includes(i))
    }

    return finalArray;
}