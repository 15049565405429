export const SET_MAIN_MENU_WEEK = "SET_MAIN_MENU_WEEK";
export const SET_PURCHASE_ORDERS_WEEK = "SET_PURCHASE_ORDERS_WEEK";

export const setMainMenuWeek = (value: any) => {
  return {
    type: SET_MAIN_MENU_WEEK,
    payload: value,
  };
};

export const setPurchaseOrdersWeek = (value: any) => {
  return {
    type: SET_PURCHASE_ORDERS_WEEK,
    payload: value,
  };
};
