import { ffPermissions } from "providers/Permissions/consts";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useDispatch } from "react-redux";
import { endSession, startSession } from "store/actions/session";
import { setUserData } from "store/actions/user";
import {
	MicrofrontendBeacon,
	MicrofrontendCommunication,
} from "utils/microfrontend-communication";

const zeroWidthIframe = document.createElement("iframe");
zeroWidthIframe.style.width = "0";
zeroWidthIframe.style.height = "0";
zeroWidthIframe.style.border = "0";
zeroWidthIframe.style.position = "absolute";
zeroWidthIframe.style.visibility = "hidden";
zeroWidthIframe.style.overflow = "hidden";
zeroWidthIframe.style.left = "0";
zeroWidthIframe.style.top = "0";
zeroWidthIframe.style.zIndex = "-1";
zeroWidthIframe.src =
	(process.env.REACT_APP_ONE_AVANT_BASE_URL ||
		window.REACT_APP_ONE_AVANT_BASE_URL) + "/auth/listener";
document.body.appendChild(zeroWidthIframe);

const context = createContext<{
	getLogoutFunction: () => () => void;
	handleTokenChange: (token?: string) => void;
}>({
	getLogoutFunction: () => {
		throw new Error("AuthProvider not found");
	},
	handleTokenChange: (token?: string) => {
		throw new Error("AuthProvider not found");
	},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const dispatch = useDispatch();
	
	const handleTokenChange = useCallback(
		(token?: string) => {
			if (token) {
				dispatch(startSession(token));
				dispatch(
					setUserData({
						email: "test",
						name: "test",
						role: "admin",
						department: "test",
					})
				);
			} else {
				dispatch(endSession());
				dispatch(
					setUserData({
						email: "",
						name: "",
						role: "",
						department: "",
					})
				);
			}
		},
		[dispatch]
	);

	useLayoutEffect(() => {
		const listener = MicrofrontendCommunication.listenTo({
			event: "TOKEN_SET",
			source: "all",
			listener: ({ token }: any) => {
				handleTokenChange(token);
			},
		});

		return () => {
			listener.close();
		};
	}, []);

	const getLogoutFunction = useCallback(() => {
		return () => {
			let beacon: MicrofrontendBeacon;
			MicrofrontendCommunication.listenTo({
				event: "LOGGED_OUT",
				source: "all",
				listener: () => {
					handleTokenChange();
					beacon?.close();
				},
			});

			beacon = MicrofrontendCommunication.beacon({
				event: "LOGOUT",
				target: "all",
				data: true,
			});
		};
	}, []);

	return (
		<context.Provider value={{ getLogoutFunction, handleTokenChange }}>
			{children}
		</context.Provider>
	);
};

export const useAuth = () => {
	const { handleTokenChange, getLogoutFunction } = useContext(context);
	const dispatch = useDispatch();

	const logout = useMemo(() => getLogoutFunction(), []);

	return {
		logout,
	};
};
