import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import type { ExtendButtonBase } from "@mui/material/ButtonBase";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import type { ListItemTypeMap } from "@mui/material/ListItem/ListItem";

interface Props extends Record<any, any> {
	icon?: React.ReactChild | React.ReactChildren;
	label?: React.ReactChild | React.ReactChildren;
	sublabel?: React.ReactChild | React.ReactChildren;
	collapsed?: boolean;
}

export const DrawerMenuItem = (({
	icon,
	label,
	sublabel,
	collapsed,
	...props
}: Props) => {
	return (
		<Tooltip title={label || ""} placement="left" hidden={!collapsed}>
			<ListItem {...props}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={label} secondary={sublabel} />
			</ListItem>
		</Tooltip>
	);
}) as ExtendButtonBase<ListItemTypeMap<Props & { button: true }, "div">> &
	OverridableComponent<ListItemTypeMap<Props & { button?: false }, "li">>;
