import initialState from "../initialState";
import {
  SET_MAIN_MENU_WEEK,
  SET_PURCHASE_ORDERS_WEEK,
} from "../actions/settings";
import { createReducer } from "@reduxjs/toolkit";

const alerts = createReducer(initialState.settings, {
  [SET_MAIN_MENU_WEEK]: (state, action) => {
    state.mainMenuWeek = action.payload;
  },
  [SET_PURCHASE_ORDERS_WEEK]: (state, action) => {
    state.purchaseOrderWeek = action.payload;
  },
});

export default alerts;
