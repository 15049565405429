import { useAuth } from "OneAvant/auth";
import OneAvantPage from "pages/OneAvant";
import { useRef } from "react";
import { useHistory } from "react-router-dom";

const Login = () => {
	const ref = useRef<HTMLIFrameElement | null>(null);
	useAuth(ref.current!);

	const _history = useHistory();
	
	return (
		<OneAvantPage
			internalPath="/auth/login"
			ref={ref}
			location={{ pathname: "/login" }}
			match={{ url: "/login" }}
			onPathLeave={(path) => {
				if (!path.includes("login")) {
					_history.replace("/")
				}
			}}
		/>
	);
};

export default Login;
