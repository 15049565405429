import apiClient from "../../services/apiClient";
import { showErrorAlert } from "./alerts";
import { getUser, unsetUser } from "./user";

export const START_SESSION = "START_TRADE_API_SESSION";
export const END_SESSION = "END_TRADE_API_SESSION";

export const startSession = (accessToken: any) => {
  return async (dispatch: any) => {
    if (!accessToken) return;

    const action = {
      type: START_SESSION,
      payload: accessToken,
    };

    dispatch(action);
  };
};

export const endSession = () => {
  return async (dispatch: any) => {
    const action = {
      type: END_SESSION,
    };
    dispatch(action);
  };
};

export const logout = () => {
  return async (dispatch: any) => {
    const action = {
      type: END_SESSION,
    };
    dispatch(action);
    dispatch(unsetUser());
  };
};

export const loginUser = (payload: any, setLoading: (value: boolean) => any) => {
  return async (dispatch: any) => {
    try {
      const responseData = await apiClient.login(payload);
      dispatch(startSession(responseData.accessToken));
      dispatch(getUser(responseData));
      setLoading(false);
    } catch (e) {
      dispatch(showErrorAlert(e));
      setLoading(false);
    }
  };
};
