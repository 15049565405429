import apiClient from "../../services/apiClient";
import { showErrorAlert } from "./alerts";

export const REMOVE_USER = "REMOVE_USER_ACTION";
export const SET_USER = "SET_USER_ACTION";

export const unsetUser = () => {
  return {
    type: REMOVE_USER,
  };
};

export const getUser = (data: any) => {
  return async (dispatch: any) => {
    try {
      const responseData = await apiClient.getUser(data);
      const action = {
        type: SET_USER,
        payload: responseData,
      };

      dispatch(action);
    } catch (e) {
      dispatch(showErrorAlert(e));
    }
  };
};


export const setUserData = (data) => {
  return async (dispatch) => {
    const action = {
      type: SET_USER,
      payload: data,
    };
    
    dispatch(action);
  };
}
