import Drawer from "@mui/material/Drawer";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import isScreenSize from "../../services/ScreenSize";
import { logout } from "../../store/actions/session";
import { ConfirmDialog } from "../Dialogs";
import { DrawerContent } from "./DrawerContent";
import { StyledDashboard } from "./StyledDashboard";
import { useAuth } from "OneAvant/auth";

const initConfirmConfig = {
	title: "",
	body: "",
	visible: false,
};

export default ({ children }: any) => {
	const dispatch = useDispatch();
	const [collapsed, setCollapsed] = useState(
		() =>
			(isScreenSize.checkScreenSize() === "laptop" &&
				localStorage.collapsed === "true") ||
			isScreenSize.checkScreenSize() !== "laptop"
	);
	const [confirmConfig, setConfirmConfig] = useState(initConfirmConfig);
	const auth = useAuth();
	const logoutUser = useCallback(() => auth.logout(), [auth.logout]);


	useEffect(() => {
		localStorage.collapsed = collapsed;
	}, [collapsed]);

	const confirmLogout = () => {
		setConfirmConfig({
			title: "Please Confirm",
			body: "Are you sure you want to logout?",
			visible: true,
		});
	};

	return (
		<>
			<ConfirmDialog
				confirmConfig={confirmConfig}
				executeActionCallback={logoutUser}
				setConfirmConfig={setConfirmConfig}
			/>
			<StyledDashboard className={`${collapsed && "collapsed"}`}>
				<Drawer variant="permanent">
					<DrawerContent
						collapsed={collapsed}
						onLogout={confirmLogout}
						toggleCollapsed={() => setCollapsed(!collapsed)}
					/>
				</Drawer>
				
				<main>
					{children}
				</main>
				
			</StyledDashboard>
		</>
	);
};
