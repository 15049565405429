const initialState = {
  session: {
    accessToken: "",
  },
  user: {
    email: "",
    name: "",
    role: "",
    department: "",
  },
  alerts: {
    success: {
      message: "",
      open: false,
    },
    error: {
      message: "",
      open: false,
    },
    info: {
      message: "",
      open: false,
    },
  },
  settings: {
    mainMenuWeek: 0,
    purchaseOrderWeek: 0,
  },
};

export default initialState;
