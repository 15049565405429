const MESSAGE_HI = "HI";
const MESSAGE_HI_BACK = "HI_BACK";
const MESSAGE_BYE = "BYE";
const MESSAGE_IM_LISTENING = "IM_LISTENING";
const PAYLOAD_SYMBOL = Symbol("MICROFRONTEND_PAYLOAD");

export type SourceTarget = "parent" | "child" | "self" | "other" | "all";

export type MicrofrontendPayload<T = any> = {
	event: string;
	data: T;
	key: string;
	target: SourceTarget;
};

export type MicrofrontendListenToArgs<T> = {
	event: string;
	listener: (data: T, event: MessageEvent) => any;
	source?: SourceTarget;
};

export type MicrofrontendListenToOnceArgs<T> = Omit<
	MicrofrontendListenToArgs<T>,
	"listener"
> & {
	listener?: MicrofrontendListenToArgs<T>["listener"];
};

export type MicrofrontendSendArgs<T> = {
	event: string;
	data: T;
	key?: string;
	target?: SourceTarget;
};

export type MicrofrontendListener = {
	close: () => void;
};

export type MicrofrontendListenerOnce<T> = {
	close: () => void;
	then: (callback: (data: T) => void) => void;
};

export type MicrofrontendBeacon = {
	close: () => void;
};

/**
 * @description this utility class handles the communication events between the browser window and any sub windows such as the iframe window
 */
export class MicrofrontendCommunication {
	private static otherWindows = new Set<MessageEventSource>();
	private static parentWindow: Window | null = null;
	// private static childWindow: MessageEventSource | null;
	private static childWindows = new Set<MessageEventSource>();

	private static messageListeners: ((event: MessageEvent) => any)[] = [];
	private static isInitialized = false;

	private static messageQueueUp: MicrofrontendPayload[] = [];
	private static messageCache: {
		[payloadKey: string]: MessageEvent; // latest event
	} = {};

	private static onMessage = (event: MessageEvent) => {
		if (this.isMicrofrontendEvent(event.data)) {
			const payloadKey = `${event.data.event}-${event.data.target}`;
			this.messageCache[payloadKey] = event;
		}
		for (const listener of this.messageListeners) {
			listener(event);
		}
	};
	static eventLoopTimeout: any;

	static isParent() {
		return !this.parentWindow;
	}

	static isChild() {
		return !!this.parentWindow;
	}

	/**
	 * @description this is important to call before being used, otherwise events won't be propagated or bubbled
	 */
	static init() {
		if (this.isInitialized) {
			console.warn("Microfrontend communication is already initialized");
			return;
		}
		window.addEventListener("message", this.onMessage);

		window.addEventListener("message", (event) => {
			if (!event.source || event.source === self) return;
			if (
				event.data?.event === MESSAGE_HI ||
				event.data?.event === MESSAGE_HI_BACK
			) {
				this.otherWindows.add(event.source);

				if (event.source === parent && parent !== self) {
					this.parentWindow = event.source;
				} else if (parent === self) {
					// this.childWindow = event.source;
                    this.childWindows.add(event.source);
				}

				if (event.data?.event === MESSAGE_HI) {
					event.source.postMessage(
						{ event: MESSAGE_HI_BACK },
						{ targetOrigin: "*" }
					);
				}

				if (event.data?.event === MESSAGE_BYE) {
					this.otherWindows.delete(event.source);
					if (this.childWindows.has(event.source)) {
						// this.childWindow = null;
                        this.childWindows.delete(event.source!);
					}
				}
			}
		});
		window.parent.postMessage({ event: MESSAGE_HI }, "*");
		this.isInitialized = true;
		this.eventLoopNext();

		self.addEventListener("beforeunload", () => {
			this.dispose();
		});
	}

	private static eventLoopNext() {
		const payload = this.messageQueueUp.shift();

		if (payload) {
			const { target } = payload;

			if (target === "parent" || target === "all") {
				if (this.parentWindow) {
					this.parentWindow.postMessage(payload, "*");
				}
			}

			if (target === "child" || target === "all") {
				// this.childWindow?.postMessage(payload, { targetOrigin: "*" });
                this.childWindows.forEach(childWindow => {
                    childWindow.postMessage(payload, { targetOrigin: "*" });
                })
			}

			if (target === "self" || target === "all") {
				self.postMessage(payload, "*");
			}

			if (target === "other" || target === "all") {
				this.otherWindows.forEach((otherWindow) => {
					otherWindow.postMessage(payload, { targetOrigin: "*" });
				});
			}
		}

		this.eventLoopTimeout = setTimeout(() => this.eventLoopNext(), 1);
	}

	private static dispose() {
		this.isInitialized = false;
		window.parent.postMessage({ event: MESSAGE_BYE }, "*");
		this.otherWindows.clear();
		window.removeEventListener("message", this.onMessage);
		clearTimeout(this.eventLoopTimeout);
	}

	static listenTo<T>({
		event: eventName,
		listener,
		source = "other",
	}: MicrofrontendListenToArgs<T>): MicrofrontendListener {
		let _lastMessageKey = "";
		let _newMessageKey = "";

		const _listener = (event: MessageEvent) => {
			if (this.isEventMatch(event.data, eventName)) {
				const target = event.data.target;
				if (!this.validateSourceTarget(source, target, event)) return;

				_newMessageKey = event.data.key;

				if (_lastMessageKey === _newMessageKey) {
					return;
				}

				_lastMessageKey = _newMessageKey;
				listener(event.data.data, event);
			}
		};

		this.messageListeners.push(_listener);

		const payloadKey = `${eventName}-${
			source === "parent" ? "child" : source === "child" ? "parent" : source
		}`;

		if (this.messageCache[payloadKey]) {
			_listener(this.messageCache[payloadKey]);
		}

		if (!eventName.startsWith(MESSAGE_IM_LISTENING)) {
			// notify about the
			this.send({
				event: MESSAGE_IM_LISTENING + ":" + eventName,
				data: Math.random(),
				target: source,
			});
		}

		return {
			close: () => {
				const index = this.messageListeners.indexOf(_listener);
				if (index >= 0) {
					this.messageListeners.splice(index, 1);
				}
			},
		};
	}

	static send<T>({
		event,
		data,
		key = JSON.stringify(data || ""),
		target = "other",
	}: MicrofrontendSendArgs<T>) {
		const payload = this.getMessagePayload({
			event,
			data,
			key,
			target,
		});

		this.messageQueueUp.push(payload);
	}

	static beacon<T>(args: MicrofrontendSendArgs<T>): MicrofrontendBeacon {
		this.send(args);
		const listener = this.listenTo({
			event: MESSAGE_IM_LISTENING + ":" + args.event,
			listener: () => {
				this.send(args);
			},
			source: args.target,
		});

		return {
			close: () => {
				listener.close();
			},
		};
	}

	private static getMessagePayload(
		payload: MicrofrontendPayload
	): MicrofrontendPayload {
		return Object.assign({ [PAYLOAD_SYMBOL]: true }, payload);
	}

	private static isMicrofrontendEvent(
		data: MessageEvent["data"]
	): data is MicrofrontendPayload {
		if (data?.[PAYLOAD_SYMBOL]) {
			return true;
		}
		return false;
	}

	private static isEventMatch(
		data: MessageEvent["data"],
		eventName: string
	): data is MicrofrontendPayload {
		if (data?.event === eventName) {
			return true;
		}

		return false;
	}

	private static validateSourceTarget(
		source: SourceTarget,
		target: SourceTarget,
		event: MessageEvent
	) {
		if (target === "all" || source === "all") return true;
		if (
			target === "child" &&
			source === "parent" &&
			event.source === this.parentWindow
		)
			return true;
		if (
			target === "parent" &&
			source === "child" &&
			this.childWindows.has(event.source!)
		)
			return true;
		if (target === "self" && source === "self" && event.source === self)
			return true;
		if (target === "other" && source === "other" && event.source !== self)
			return true;

		return false;
	}
}
