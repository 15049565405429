import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import useStoreAlertsSelector from "../../../hooks/useStoreAlertsSelector";
import { hideErrorAlert } from "../../../store/actions/alerts";
import "./ErrorAlert.scss";

const ErrorAlert = () => {
	const storeAlerts = useStoreAlertsSelector();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(hideErrorAlert());
	};

	return (
		<Snackbar
			TransitionComponent={Slide}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			autoHideDuration={5000}
			className="errorAlert"
			onClose={handleClose}
			open={storeAlerts.error.open}
		>
			<Alert onClose={handleClose} severity="error">
				<AlertTitle>Error!</AlertTitle>
				{typeof storeAlerts.error?.message === "string"
					? storeAlerts.error?.message
					: storeAlerts.error?.message?.code
					? storeAlerts.error?.message?.code
					: storeAlerts.error?.message?._message
					? storeAlerts.error?.message?._message
					: "Something went wrong!"}
			</Alert>
		</Snackbar>
	);
};

export default ErrorAlert;
