import React from "react";
import { createRoot } from 'react-dom/client';
import "./assets/styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { Router } from "react-router-dom";
import history from "./services/history";
import { PermissionsProiderWrapper } from "components/PermissionsProviderWrapper";
import moment from "moment";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AuthProvider } from "OneAvant/auth";
import { MicrofrontendCommunication } from "utils/microfrontend-communication";

moment.locale('mt')

const root = createRoot(document.getElementById("root")!)

MicrofrontendCommunication.init()

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AuthProvider>
        <PermissionsProiderWrapper>
          <Router history={history}>
            <App />
          </Router>
        </PermissionsProiderWrapper>
      </AuthProvider>
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


serviceWorkerRegistration.register({
  onSuccess: (registration) => {
    console.log(registration)
  },
  onUpdate: (registration) => {
    if (window.confirm("There is a new version of the browser, Update?")) {
      registration.waiting!.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload()
    }
  },
});
