import initialState from "../initialState";
import {
  SHOW_ERROR_ALERT,
  HIDE_ERROR_ALERT,
  SHOW_SUCCESS_ALERT,
  HIDE_SUCCESS_ALERT,
  SHOW_INFO_ALERT,
  HIDE_INFO_ALERT,
} from "../actions/alerts";
import { createReducer } from "@reduxjs/toolkit";

const alerts = createReducer(initialState.alerts, {
  [SHOW_ERROR_ALERT]: (state, action) => {
    state.error.open = true;
    state.error.message = action.payload;
  },

  [HIDE_ERROR_ALERT]: () => {
    return initialState.alerts;
  },

  [SHOW_SUCCESS_ALERT]: (state, action) => {
    state.success.open = true;
    state.success.message = action.payload;
  },

  [HIDE_SUCCESS_ALERT]: () => {
    return initialState.alerts;
  },

  [SHOW_INFO_ALERT]: (state, action) => {
    state.info.open = true;
    state.info.message = action.payload;
  },

  [HIDE_INFO_ALERT]: () => {
    return initialState.alerts;
  },
});

export default alerts;
