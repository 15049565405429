import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import "./NoResourceAvailable.scss";
import CustomButton from "../CustomButton/CustomButton";
import no_data from "assets/images/no_data_recipe.gif";
import { CircularProgress } from "@mui/material";

export interface INoResourceAvailableProps {
	resource?: string;
	image?: string;
	loading?: boolean;
	addButtonLine?: boolean;
	message?: string;
}

/** A general purpose component to handle the UI element telling the user that there's no resource available
 * now includes a loading spinner!
 */
const NoResourceAvailable = ({
	image = no_data,
	resource = "Entity",
	addButtonLine = false,
	loading = false,
	message = "",
}: INoResourceAvailableProps) => {
	return (
		<>
			<Box
				alignItems="center"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				className="noResources"
			>
				<img src={image} alt={resource} width="300px"></img>
			</Box>
			<Box my="30px">
				<Typography align="center" variant="h3" className="yellow">
					{/* If loading show the loading spinner */}
					{loading && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								width: "100%",
								color: "#cb3440",
							}}
						>
							<CircularProgress style={{ color: "#cb3440" }} />
						</div>
					)}
					{/* If not loading show the content */}
					{!loading ? <>
						{message
							? message
							: `There are no ${resource} in the system at the moment.`}{" "}
						<br />
						{addButtonLine && (
							<p>
								Click{" "}
								<CustomButton className="iconButton verysmall mx-2">
									<AddIcon className="icon" />
								</CustomButton>{" "}
								on the top right to add one!
							</p>
						)}
					</> : <></>}
				</Typography>
			</Box>
		</>
	);
};

export default NoResourceAvailable;
