import initialState from "../initialState";
import { createReducer } from "@reduxjs/toolkit";
import { START_SESSION, END_SESSION } from "../actions/session";

const session = createReducer(initialState.session, {
  [START_SESSION]: (state, action) => {
    state.accessToken = action.payload;
  },

  [END_SESSION]: () => {
    return initialState.session;
  },
});

export default session;
