import "./SuccessAlert.scss";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import useStoreAlertsSelector from "../../../hooks/useStoreAlertsSelector";
import { useDispatch } from "react-redux";
import { hideSuccessAlert } from "../../../store/actions/alerts";
import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";

const SuccessAlert = () => {
	const storeAlerts = useStoreAlertsSelector();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(hideSuccessAlert());
	};

	return (
		<Snackbar
			TransitionComponent={Slide}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			autoHideDuration={5000}
			className="successAlert"
			onClose={handleClose}
			open={storeAlerts.success.open}
		>
			<Alert onClose={handleClose} severity="success">
				<AlertTitle>Success</AlertTitle>
				{storeAlerts.success.message && storeAlerts.success.message}
			</Alert>
		</Snackbar>
	);
};

export default SuccessAlert;
