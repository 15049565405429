import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
  key: "foodFactory",
  storage,
  stateReconciler: autoMergeLevel2,
  version: 2,
  blacklist: ["alerts"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);
export const persistor = persistStore(store);
