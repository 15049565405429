
import { BaseApi, Payload } from './base';


export default new class Departments extends BaseApi {

    async getDepartments(payload: Payload) {
        const endpointPath = `/departments`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getDepartmentNames(payload: Payload) {
        const endpointPath = `/departments/names`;
        return await this.doRequest(endpointPath, payload, "GET");
    }

    async getDepartmentEmployees(payload: Payload, abortController = new AbortController()) {
        const endpointPath = `/employees/department/${payload.departmentName}`;
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async getSubDepartments(payload: Payload, abortController = new AbortController()) {
        const endpointPath = `/departments/${payload.departmentName}/subdepartments`;
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async deleteSubDepartment(payload: Payload) {
        const endpointPath = `/departments/${payload.name}/subdepartments/${payload.id}`;
        return await this.doRequest(endpointPath, payload, "DELETE");
    }

    async createSubdepartment(payload: Payload) {
        const endpointPath = `/departments/${payload.departmentName}/subdepartments`;
        return await this.doRequest(endpointPath, payload, "POST");
    }

    async updateSubDepartment(payload: Payload) {
        const endpointPath = `/departments/${payload.department}/subdepartments/${payload.id}`;
        return await this.doRequest(endpointPath, payload, "PUT");
    }
}