import { useSelector } from "react-redux";

/** Made by Awais, December 2020 */
const useStoreUserSelector = () => {
  const selectStoreAlerts = (state: any) => state.user.data;

  return useSelector(selectStoreAlerts);
};

export default useStoreUserSelector;
