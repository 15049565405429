
import { BaseApi, Payload } from './base';


export default new class WeeklyStatistics extends BaseApi {
    async getWeeklyStatsForOrder(payload: Payload, abortController = new AbortController) {
        const endpointPath = "/ordersWeeklyStats";
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async getWeeklyStatsForProducts(payload: Payload, abortController = new AbortController) {
        const endpointPath = "/productsWeeklyStats";
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async getWeeklyStatsForRosters(payload: Payload, abortController = new AbortController) {
        const endpointPath = "/rostersWeeklyStats";
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async getWeeklyDepartmentStats(payload: Payload, abortController = new AbortController) {
        const endpointPath = "/departmentWeeklyStats";
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }

    async getProductionPlansWeeklyStats(payload: Payload, abortController = new AbortController) {
        const endpointPath = "/plans-stats";
        return await this.doRequest(endpointPath, payload, "GET", { signal: abortController.signal });
    }
}