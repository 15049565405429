import { DecodedToken } from "interfaces/oneavant/authenticated-user";
import jwtDecode from "jwt-decode";
import { useMemo } from "react";
import useStoreSessionSelector from "./useStoreSessionSelector";
export const useUserPermissions = () => {
	const token = useStoreSessionSelector();

	const userData = useMemo(() => {
		if (token.accessToken) {
			try {
				const decodedToken = jwtDecode<DecodedToken>(token.accessToken);
				return decodedToken.data;
			} catch (e) {}
			return null;
		}
	}, [token.accessToken]);
	return useMemo(
		() => [...(userData?.roles ?? ["guest"]), ...(Array.isArray(userData?.permissions) ? userData.permissions :  [])],
		[userData]
	);
};
