import { useSelector } from "react-redux";

/** Made by Awais, December 2020 */
const useStoreAlertsSelector = () => {
  const selectStoreAlerts = (state: any) => state.alerts;

  return useSelector(selectStoreAlerts);
};

export default useStoreAlertsSelector;
