import { MicrofrontendCommunication } from "utils/microfrontend-communication";

export const ffPermissions: {
	code: string;
	label: string;
	category: string;
}[] = [
    {
		code: "access kpi dashboard",
		label: "access kpi dashboard",
		category: "FF Operations",
	},
	{
		code: "access indorcify",
		label: "Access Indorcify",
		category: "FF Operations",
	},
	{ code: "access logstock", label: "Access Logstock", category: "FF Operations" },
	{ code: "access production plans", label: "Access production plans", category: "FF Operations" },
	{ code: "delete production plans", label: "Delete production plans", category: "FF Operations" },
	{ code: "access recipe cards", label: "Access Recipe Cards", category: "FF Operations" },
	{ code: "update recipe cards", label: "Update Recipe Cards", category: "FF Operations" },
	{ code: "delete recipes", label: "Delete Recipes", category: "FF Operations" },
	{ code: "access recipe costs", label: "Access Recipe Costs", category: "FF Operations" },
];

// you may define the permissions of the system here
// export const systemPermissions = [
// 	"access indorcify",
// 	"access logstock",
// 	"update recipes",
// 	"delete recipes",
// 	"access recipe costs",
// 	...definePermission("weekly statistics", ["read"]),
// 	...definePermission("recipe cards", ["read", "create", "update", "delete"]),
// 	...definePermission("production plans", [
// 		"read",
// 		"create",
// 		"update",
// 		"delete",
// 	]),
// ];
