import { useUserPermissions } from "hooks/useUserPermissions";
import { PermissionsProvider } from "providers/Permissions";
import React, { useEffect, useState } from "react";


export const PermissionsProiderWrapper = ({ children }: { children: React.ReactNode }) => {
    const userPermissions = useUserPermissions()
    
    return <PermissionsProvider value={{
        userPermissions,
    }}>
        {children}
    </PermissionsProvider>
}