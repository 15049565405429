import React from "react";

export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT";
export const HIDE_ERROR_ALERT = "HIDE_ERROR_ALERT";
export const SHOW_SUCCESS_ALERT = "SHOW_SUCCESS_ALERT";
export const HIDE_SUCCESS_ALERT = "HIDE_SUCCESS_ALERT";
export const SHOW_INFO_ALERT = "SHOW_INFO_ALERT";
export const HIDE_INFO_ALERT = "HIDE_INFO_ALERT";

export const showErrorAlert = (error: any) => {
  return {
    type: SHOW_ERROR_ALERT,
    payload: error.message,
  };
};

export const hideErrorAlert = () => {
  return {
    type: HIDE_ERROR_ALERT,
  };
};

export const showSuccessAlert = (message: string | React.ReactNode) => {
  return {
    type: SHOW_SUCCESS_ALERT,
    payload: message,
  };
};

export const hideSuccessAlert = () => {
  return {
    type: HIDE_SUCCESS_ALERT,
  };
};


export const showInfoAlert = (message: string) => {
  return {
    type: SHOW_INFO_ALERT,
    payload: message,
  };
};

export const hideInfoAlert = () => {
  return {
    type: HIDE_INFO_ALERT,
  };
};
