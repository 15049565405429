import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Assignment from "@mui/icons-material/Assignment";
import ContactMail from "@mui/icons-material/ContactMail";
import Person from "@mui/icons-material/Person4";
import Suppliers from "@mui/icons-material/LocalShipping";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { WithPermissions } from "providers/Permissions";
import React from "react";
import { NavLink } from "react-router-dom";
import LaptopIcon_MHC from "../../assets/images/loginLogo_mhc.png";
import LaptopIcon_FF from "../../assets/images/logoIcon.svg";
import user_image_placeholder from "../../assets/images/user_image_placeholder.png";
import useStoreUserSelector from "../../hooks/useStoreUserSelector";
import MainMenuIcon from "../SideMenu/SidebarIcons/MainMenuIcon";
import ProductPricesIcon from "../SideMenu/SidebarIcons/ProductPricesIcon";
import RecipeCardsIcon from "../SideMenu/SidebarIcons/RecipeCardsIcon";
import { DrawerMenuItem } from "./DrawerMenuItem";
import { Warehouse } from "@mui/icons-material";

export const DrawerContent: React.FC<{
	onLogout: () => any;
	toggleCollapsed?: () => any;
	collapsed: boolean;
}> = ({ collapsed, onLogout, toggleCollapsed }) => {
	const user = useStoreUserSelector();

	let instanceName = window.INSTANCE_NAME || "ff";

	return (
		<>
			{toggleCollapsed && (
				<Button
					className="collapse-button"
					size={"large"}
					color={"inherit"}
					onClick={toggleCollapsed}
					id="close-menu-button"
				>
					<Box flex={1} />
					<ArrowBackIosIcon className="icon" />
				</Button>
			)}
			<Box
				component={"img"}
				src={(instanceName === "ff" ? LaptopIcon_FF : LaptopIcon_MHC) as string}
				className={"logo-image"}
				maxHeight={"80px"}
				p={1}
				my={2}
				width={"100%"}
			/>
			<WithPermissions only={["access kpi dashboard"]}>
				<DrawerMenuItem
					icon={<MainMenuIcon />}
					label={"KPI Dashboard"}
					component={NavLink}
					to={"/weeklyStatistics"}
				/>
			</WithPermissions>
			<WithPermissions only={["access indorcify"]}>
				<DrawerMenuItem
					label={"Indorcify"}
					icon={<Person />}
					component={NavLink}
					to={"/indorcify"}
				/>
			</WithPermissions>
			<WithPermissions only={["access logstock"]}>
				<DrawerMenuItem
					label={"Logstock"}
					icon={<Warehouse />}
					component={NavLink}
					to={"/logstock"}
				/>
			</WithPermissions>
			<WithPermissions only={["LS.SUP.M"]}>
				<DrawerMenuItem
					label={"Suppliers"}
					icon={<Suppliers />}
					component={NavLink}
					to={"/suppliers"}
				/>
			</WithPermissions>
			<WithPermissions only={["LS.MAT.M"]}>
				<DrawerMenuItem
					label={"Material Specs"}
					icon={<ProductPricesIcon />}
					component={NavLink}
					to={"/materials"}
				/>
			</WithPermissions>
			<WithPermissions only={["access recipe cards"]}>
				<DrawerMenuItem
					label={"Recipe Cards"}
					icon={<RecipeCardsIcon />}
					component={NavLink}
					to={"/recipeCards"}
				/>
			</WithPermissions>
			<WithPermissions only={["access production plans"]}>
				<DrawerMenuItem
					label={"Production Plans"}
					icon={<Assignment />}
					component={NavLink}
					to={"/productionPlans"}
				/>
			</WithPermissions>
			<WithPermissions only={["CO.US.M"]}>
				<DrawerMenuItem
					icon={<PermIdentityIcon />}
					label={"Manage Accounts"}
					component={NavLink}
					to={"/manageAccount"}
				/>
			</WithPermissions>
			<DrawerMenuItem
				icon={<ExitToAppIcon />}
				label={"Logout"}
				button
				onClick={onLogout}
			/>
			<Box flex={1} marginTop={"40px"} />
			{!collapsed && (
				<>
					<Divider />
					<Typography
						component={"a"}
						href={"https://avant.gs"}
						target={"_blank"}
						align={"center"}
						style={{
							fontSize: "12px",
							padding: "20px",
							color: "rgba(255, 255, 255, 0.5)",
							textDecoration: "none",
						}}
					>
						Solution by <b>Avant.GS</b>
					</Typography>
				</>
			)}
		</>
	);
};
