const ProductPricesIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g id="Group_392" data-name="Group 392" transform="translate(5498 -4089)">
				<rect
					id="Rectangle_184"
					data-name="Rectangle 184"
					width="24"
					height="24"
					transform="translate(-5498 4089)"
					fill="none"
				/>
				<path
					id="Path_510"
					data-name="Path 510"
					d="M17.657,6.343A8,8,0,1,0,20,12,7.948,7.948,0,0,0,17.657,6.343ZM12,11.5a2.25,2.25,0,0,1,.615,4.414.157.157,0,0,0-.115.151v.422a.509.509,0,0,1-.476.513.5.5,0,0,1-.524-.5v-.436a.157.157,0,0,0-.115-.15,2.254,2.254,0,0,1-1.635-2.15.508.508,0,0,1,.485-.514.5.5,0,0,1,.515.5A1.25,1.25,0,1,0,12,12.5a2.25,2.25,0,0,1-.615-4.414.157.157,0,0,0,.115-.151V7.514A.509.509,0,0,1,11.976,7a.5.5,0,0,1,.524.5v.436a.157.157,0,0,0,.115.15,2.254,2.254,0,0,1,1.635,2.15.508.508,0,0,1-.485.514.5.5,0,0,1-.515-.5A1.25,1.25,0,1,0,12,11.5Z"
					transform="translate(-5498 4089)"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
};

export default ProductPricesIcon;
