type RecipeCardIconProps = {
  className?: string;
}

const RecipeCardsIcon = (props: RecipeCardIconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g id="Group_394" data-name="Group 394" transform="translate(5467 -4089)">
        <rect id="Rectangle_185" data-name="Rectangle 185" width="24" height="24" transform="translate(-5467 4089)" fill="none" />
        <g id="Group_393" data-name="Group 393" transform="translate(-5466.063 4088.938)">
          <path id="Path_511" data-name="Path 511" d="M16.016,5.125a2.893,2.893,0,0,0-1.052.2A4.213,4.213,0,0,1,15.6,6.648a.266.266,0,1,1-.512.141A3.719,3.719,0,0,0,8.868,5.154a3.453,3.453,0,0,1,1.569,2.893.266.266,0,1,1-.531,0,2.922,2.922,0,1,0-3.512,2.862.266.266,0,0,1,.209.216l.872,5.156H9.09l-.245-3.434h0c0-.006,0-.012,0-.019a.266.266,0,0,1,.53-.019h0l.248,3.472h1.612V13.359a.266.266,0,1,1,.531,0v2.922h1.612l.248-3.472h0a.266.266,0,0,1,.53.019c0,.006,0,.013,0,.019h0l-.245,3.434h1.615l.872-5.156a.266.266,0,0,1,.209-.216,2.922,2.922,0,0,0-.59-5.784Z" fill="currentColor" />
          <path id="Path_512" data-name="Path 512" d="M7.516,17.281h7.969v2.125H7.516Z" fill="currentColor" />
        </g>
      </g>
    </svg>
  )
}

export default RecipeCardsIcon;
