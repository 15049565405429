
import { BaseApi, Payload } from './base';


export default new class Users extends BaseApi {

    async addUser(payload: Payload) {
        return await this.doRequest("/users", payload, "POST");
    }

    async getUser(payload: Payload) {
        return await this.doRequest(`/users/${payload.userId}`, payload, "GET");
    }

    async getUserRoles(payload: Payload) {
        return await this.doRequest('/users/roles', payload, "GET");
    }

    async getUsers(payload: Payload) {
        console.log('payload', payload)
        return await this.doRequest(`/users`, payload, "GET");
    }

    async deleteUser(payload: Payload) {
        return await this.doRequest(`/users/${payload.userId}`, payload, "DELETE");
    }

    async updateUser(payload: Payload) {
        return await this.doRequest(`/users/${payload.userId}`, payload, "PUT");
    }

}
