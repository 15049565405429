import NoResourceAvailable from "components/NoResourceAvailable";
import { useWithPermissions } from "providers/Permissions";
import React from "react";
import { Route } from "react-router-dom";

type PermissionRouteProps = {
	exact?: boolean;
	path?: string | string[];
	component?: any; // @to-do - Find a better type for this "React.LazyExoticComponent<() => JSX.Element> | React.ReactNode" doesn't seems to work
	otherwise?: React.ReactNode;
	only?: string[] | undefined;
	except?: string[] | undefined;
	onlyAll?: string[] | undefined;
	exceptAll?: string[] | undefined;
	children?: React.ReactNode;
	componentProps?: any;

	// for all other props
	[key: string]: any;
};

export default function PermissionRoute({
	component: Component,
	otherwise = (
		<div
			style={{
				minHeight: "100vh",
				display: "grid",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div>
				<NoResourceAvailable message="This page is unauthorized, please contact administration." />
			</div>
		</div>
	),
	only,
	except,
	onlyAll,
	exceptAll,
	children,
	componentProps,
	...routeProps
}: PermissionRouteProps) {
	const { permitted } = useWithPermissions({
		only,
		onlyAll,
		except,
		exceptAll,
	});

	return (
		<Route
			{...routeProps}
			render={(props) => {
				return permitted ? (
					<>
						{Component ? (
							<Component {...componentProps} {...props} />
						) : (
							<>{children}</>
						)}
					</>
				) : (
					<>{otherwise}</>
				);
			}}
		/>
	);
}
