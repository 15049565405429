class ScreenSize {

    /**
     * Checks the size of the screen and returns a string 
     * @returns "mobile", "tablet", or "laptop"
     */
    checkScreenSize(): string {
        const screenWidth: number = window.screen.width;

        if (screenWidth < 425) {
            return "mobile";
        } else if (screenWidth <= 768 && screenWidth >= 425) {
            return "tablet";
        } else {
            return "laptop";
        }
    }
}

const isScreenSize = new ScreenSize();

Object.freeze(isScreenSize);

export default isScreenSize;