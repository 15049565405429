import "whatwg-fetch";
import jwt_decode from "jwt-decode";

// @ts-ignore
export const baseUrl = process.env.REACT_APP_API_URL
	? process.env.REACT_APP_API_URL
	: // @ts-ignore
	  window.REACT_APP_API_URL;

export type Payload<P = any> = P extends FormData
	? P
	: {
			accessToken?: string;
			query?: P extends { query: any } ? P["query"] : any;
			data?: P extends { data: any } ? P["data"] : any;
	  } & P;

export class BaseApi {
	baseUrl: string;
	constructor() {
		this.baseUrl = baseUrl;
	}

	async doRequest<R = any, P = any>(
		endpointPath: string,
		payload: Payload<P>,
		method: string = "GET",
		_options?: RequestInit
	): Promise<R> {
		let requestUrl =
			this.baseUrl.replace(/\/$/, "") + "/" + endpointPath.replace(/^\//, "");
		const isFormDataInstance = payload instanceof FormData;
		const accessToken = !isFormDataInstance
			? payload.accessToken
			: payload.get("accessToken");

		let options: RequestInit & { headers: any } = {
			..._options,
			method: method,
			headers: {},
		};

		if (!isFormDataInstance) {
			options.headers["Content-Type"] = "application/json";
		}

		// Sending the client time to the backend, very important for production plans
		options.headers["X-Client-Time"] = new Date().toJSON();

		if (accessToken) {
			const decodedToken: any = jwt_decode(accessToken as string);
			const timeStamp = decodedToken.tokenValidUntil;
			if (timeStamp < Date.now()) {
				localStorage.clear();
				window.location.href = "/login";
			}

			options.headers.Authorization = `Bearer ${accessToken}`;
		}

		if (method === "POST" || method === "PUT" || method === "DELETE") {
			options.body = isFormDataInstance ? payload : JSON.stringify(payload);
		}

		if (method === "GET" && payload && "query" in payload) {
			requestUrl = `${requestUrl}?${new URLSearchParams(payload.query)}`;
		}

		const response = await fetch(requestUrl, options);
		const parsedJson = await response.json();
		if (response.status === 200) {
			return parsedJson as R;
		} else {
			throw parsedJson;
		}
	}

	extend<D extends BaseApi>(domain: D) {
		const that = this;
		const ThisClassProto = Object.getPrototypeOf(this);
		const DomainClassProto = Object.getPrototypeOf(domain);

		// merge constructors
		class ExtendedApi {
			constructor() {
				Object.assign(this, that);
				Object.assign(this, domain);
			}
		}

		// merge request functions
		for (const requestName of Object.getOwnPropertyNames(ThisClassProto)) {
			//@ts-ignore
			ExtendedApi.prototype[requestName] = ThisClassProto[requestName];
		}

		for (const requestName of Object.getOwnPropertyNames(DomainClassProto)) {
			//@ts-ignore
			ExtendedApi.prototype[requestName] = DomainClassProto[requestName];
		}

		// create a new instance
		const extended = new ExtendedApi() as typeof this & D;

		// merge properties
		Object.assign(extended, this, domain);

		return extended;
	}
}
