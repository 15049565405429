const MainMenuIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24">
			<g id="Group_389" data-name="Group 389" transform="translate(5561 -4087)">
				<rect
					id="Rectangle_182"
					data-name="Rectangle 182"
					width="24"
					height="24"
					transform="translate(-5561 4087)"
					fill="none"
				/>
				<g
					id="Group_388"
					data-name="Group 388"
					transform="translate(-5561 4087)"
				>
					<path
						id="Path_502"
						data-name="Path 502"
						d="M10.769,4H4.615A.615.615,0,0,0,4,4.615v6.154a.615.615,0,0,0,.615.615h6.154a.615.615,0,0,0,.615-.615V4.615A.615.615,0,0,0,10.769,4Z"
						fill="currentColor"
					/>
					<path
						id="Path_503"
						data-name="Path 503"
						d="M19.385,4H13.231a.615.615,0,0,0-.615.615v6.154a.615.615,0,0,0,.615.615h6.154A.615.615,0,0,0,20,10.769V4.615A.615.615,0,0,0,19.385,4Z"
						fill="currentColor"
					/>
					<path
						id="Path_504"
						data-name="Path 504"
						d="M10.769,12.615H4.615A.615.615,0,0,0,4,13.231v6.154A.615.615,0,0,0,4.615,20h6.154a.615.615,0,0,0,.615-.615V13.231A.615.615,0,0,0,10.769,12.615Z"
						fill="currentColor"
					/>
					<path
						id="Path_505"
						data-name="Path 505"
						d="M19.385,12.615H13.231a.615.615,0,0,0-.615.615v6.154a.615.615,0,0,0,.615.615h6.154A.615.615,0,0,0,20,19.385V13.231A.615.615,0,0,0,19.385,12.615Z"
						fill="currentColor"
					/>
				</g>
			</g>
		</svg>
	);
};

export default MainMenuIcon;
